import { initializeApp } from 'firebase/app'
import {
    getAuth,
    reauthenticateWithCredential,
    EmailAuthProvider,
    signInWithEmailAndPassword,
    signOut,
    FacebookAuthProvider,
    signInWithPopup,
    createUserWithEmailAndPassword,
    updatePassword,
    sendPasswordResetEmail,
    sendEmailVerification,
    deleteUser,
    fetchSignInMethodsForEmail,
    connectAuthEmulator,
} from 'firebase/auth'
import { getStorage } from 'firebase/storage'
import {
    doc,
    getDoc,
    collection,
    query,
    where,
    getDocs,
    getFirestore,
    serverTimestamp,
    FieldValue,
    connectFirestoreEmulator,
} from 'firebase/firestore'
import {
    getFunctions,
    httpsCallable,
    connectFunctionsEmulator,
} from 'firebase/functions'
import { extractHostnameAndPort } from './util/firebase'

const ENV = process.env

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: ENV.VUE_APP_FIREBASE_API_KEY,
    authDomain: ENV.VUE_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: ENV.VUE_APP_FIREBASE_DATABASE_URL,
    projectId: ENV.VUE_APP_FIREBASE_PROJECT_ID,
    storageBucket: ENV.VUE_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: ENV.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: ENV.VUE_APP_FIREBASE_APP_ID,
    measurementId: ENV.VUE_APP_FIREBASE_MEASUREMENT_ID,
}
// Initialize Firebase
const app = initializeApp(firebaseConfig)

const storage = getStorage(app)

const auth = getAuth(app)
if (process.env.VUE_APP_FIREBASE_EMULATOR) {
    connectAuthEmulator(auth, process.env.VUE_APP_FIREBASE_LOCAL_AUTH)
}

const db = getFirestore(app)
if (process.env.VUE_APP_FIREBASE_EMULATOR) {
    const { hostname, port } = extractHostnameAndPort(
        process.env.VUE_APP_FIREBASE_LOCAL_FIRESTORE
    )
    connectFirestoreEmulator(db, hostname, parseInt(port))
}

const functions = getFunctions(app)
if (process.env.VUE_APP_FIREBASE_EMULATOR) {
    const { hostname, port } = extractHostnameAndPort(
        process.env.VUE_APP_FIREBASE_LOCAL_FUNCTION
    )
    connectFunctionsEmulator(functions, hostname, parseInt(port))
}

export {
    auth,
    doc,
    getDoc,
    getDocs,
    collection,
    query,
    where,
    db,
    storage,
    FieldValue,
    signInWithEmailAndPassword,
    signOut,
    FacebookAuthProvider,
    EmailAuthProvider,
    reauthenticateWithCredential,
    signInWithPopup,
    functions,
    httpsCallable,
    serverTimestamp,
    createUserWithEmailAndPassword,
    updatePassword,
    sendPasswordResetEmail,
    sendEmailVerification,
    deleteUser,
    fetchSignInMethodsForEmail,
}
