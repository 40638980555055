<template>
    <v-row>
        <v-text-field
            v-model="search"
            outlined
            solo
            hide-details
            append-icon="mdi-magnify"
            :label="$t('message.searchByEmail')"
            @keyup.enter="searchUser"
            @keyup="searchUserOnKeyUp($event)"
            @keyup.delete="searchUserOnKeyUp($event)"
        ></v-text-field>
    </v-row>
</template>

<script>
import { getUserDisplayDataByEmail } from '@/util/cloudFunctions/getUserDisplayDataByEmail'
export default {
    data() {
        return {
            search: '',
            users: [],
            timer: null,
        }
    },
    methods: {
        validateEmailFormat(email) {
            const emailRegex = new RegExp(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
            return emailRegex.test(email)
        },

        async searchUser() {
            const search = this.search.toLowerCase().trim() // lowercase because username are all lowercase letters, and trim empty spaces
            if (!this.validateEmailFormat(search)) {
                this.$emit('userSearched', {
                    users: this.users,
                    noUserFound: false,
                })
                return
            }

            if (search === '') {
                this.users = []
                this.$emit('userSearched', {
                    users: this.users,
                    noUserFound: false,
                })
                return
            }
            this.users = []

            const searchResult = await getUserDisplayDataByEmail(search)
            if (searchResult.userExists) {
                this.users.push(searchResult)
            }

            this.$emit('userSearched', {
                users: this.users,
                noUserFound: true,
            })
        },
        searchUserOnKeyUp(event) {
            if (event.code == 'Enter') return // To prevent double trigger of the "Enter" event
            if (this.timer) {
                clearTimeout(this.timer)
                this.timer = null
            }
            this.timer = setTimeout(() => {
                this.searchUser()
            }, 300)
        },
    },
}
</script>
